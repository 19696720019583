/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PaypalSmartButtons from '../../component/PaypalSmartButtons';
import { PAYPAL_PLACEMENT_PRODUCT_PAGE } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPaypalSmartButtonsConfigToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        paypalConfigs: state.ConfigReducer?.paypalConfigs
    };
};

const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    hasError: instance.hasError.bind(instance)
});

const addPaypalSmartButtonsConfigToContainerProps = (args, callback, instance) => {
    const {
        paypalConfigs
    } = instance.props;

    return {
        ...callback(...args),
        paypalConfigs
    };
};

const addPayPalButtonToProductActions = (args, callback, instance) => {
    const {
        addToCart,
        hasError,
        paypalConfigs: {
            isVisibleOnProductPage = false
        } = {}
    } = instance.props;

    if (!isVisibleOnProductPage) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <PaypalSmartButtons
              page={ PAYPAL_PLACEMENT_PRODUCT_PAGE }
              addToCart={ addToCart }
              hasError={ hasError }
            />
        </>
    );
};

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: addPaypalSmartButtonsConfigToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerFunctions,
            containerProps: addPaypalSmartButtonsConfigToContainerProps
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderMobile: addPayPalButtonToProductActions,
            renderDesktop: addPayPalButtonToProductActions
        }
    }
};
