/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa-ProductReviewListtheme
 */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable comma-dangle */
/* eslint-disable padded-blocks */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-no-useless-fragment */

import { ProductPageComponent as SourceProductPageComponent } from 'Source/route/ProductPage/ProductPage.component';

import ContentWrapper from 'Component/ContentWrapper';
import ProductGallery from 'Component/ProductGallery';
import NoMatchHandler from 'Route/NoMatchHandler';
import MusiqueDepotCmsBlocks from 'Component/MusiqueDepotCmsBlocks';
import ProductActions from 'Component/ProductActions';
import {
    MD_WIDGET_CMS_BLOCK_AREA_PDP_TOP,
    MD_WIDGET_CMS_BLOCK_ENTITY_TYPE_PRODUCT
} from 'Component/MusiqueDepotCmsBlocks/MusiqueDepotCmsBlocks.config';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from '@scandipwa/scandipwa/src/component/ChevronIcon/ChevronIcon.config';

import './ProductPage.override.style.scss';

/** @namespace Scandipwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    renderNavigationBlock() {
        const { activeProduct: { name = '' } = {} } = this.props;

        return (
            <div block="ProductNavigation" elem="Wrapper">
                <button
                    block="ProductNavigation"
                    elem="ButtonBack"
                    onClick={() => history.back()}
                >
                    <ChevronIcon direction={Directions?.LEFT} />
                </button>
                <h1 block="ProductNavigation" elem="Title"><span>{name}</span></h1>
            </div>
        );
    }

    /*
    * Overridden to add navigation block
     */
    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
            isMobile
        } = this.props;

        return (
            <>
                {isMobile && this.renderNavigationBlock()}
                <ProductGallery
                    product={activeProduct}
                    areDetailsLoaded={areDetailsLoaded}
                    isWithEmptySwitcher={useEmptyGallerySwitcher}
                    showLoader={isVariant}
                />
                <ProductActions
                    getLink={getLink}
                    product={dataSource}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    setActiveProduct={setActiveProduct}
                />
            </>
        );
    }

    renderPdpTopCMSBlockWidgets() {
        const { pdpProductId } = this.props;

        if (pdpProductId) {
            return null;
        }

        return (
            <>
                <MusiqueDepotCmsBlocks
                    referenceContainer={MD_WIDGET_CMS_BLOCK_AREA_PDP_TOP}
                    entityType={MD_WIDGET_CMS_BLOCK_ENTITY_TYPE_PRODUCT}
                    entityId={pdpProductId}
                />
            </>
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                >
                    {/* {this.renderPdpTopCMSBlockWidgets()} */}
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}
                    </ContentWrapper>
                    {this.renderAdditionalSections()}
                    {this.renderReviewPopup()}
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
