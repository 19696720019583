import {
    ImageZoomPopupComponent as SourceImageZoomPopup,
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Scandipwa/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopup {
    // Oveddirren to add styles
}

export default ImageZoomPopupComponent;
