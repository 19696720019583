/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper/ContentWrapper.component';
import ProductCard from 'Component/ProductCard';

import './MoreFromBrand.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Component */
export class MoreFromBrandComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape).isRequired
    };

    renderHeader() {
        const { title } = this.props;

        return (
            <div block="ProductLinks" elem="Title">
                { title }
            </div>
        );
    }

    renderCard() {
        const { items } = this.props;

        if (!items) {
            return null;
        }

        return items.map(this.renderCardItem);
    }

    renderCardItem(item) {
        return <ProductCard product={ item } key={ item.id } />;
    }

    render() {
        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
            >
                { this.renderHeader() }
                <div block="ProductLinks" elem="List">
                    { this.renderCard() }
                </div>
            </ContentWrapper>
        );
    }
}

export default MoreFromBrandComponent;
