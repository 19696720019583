/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrandDetails from '../../component/BrandDetails';

const renderBrandDetails = (args, callback, instance) => {
    const {
        product: {
            attributes: {
                manufacturer: {
                    attribute_options = {}
                } = {}
            } = {}
        } = {}
    } = instance.props;

    const brandData = Object.values(attribute_options);

    if (!brandData.length) {
        return callback(args);
    }

    const brandId = Number(brandData[0].value);

    return <BrandDetails brandId={ brandId } />;
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderBrand: renderBrandDetails
        }
    }
};
