/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { ONE_DAY } from 'Util/Compare';
import { getIndexedProduct } from 'Util/Product';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import DataContainer from 'Util/Request/DataContainer';
import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request/Query';
import BrandsMoreFromBrandQuery from '../../query/BrandsMoreFromBrand.query';
import MoreFromBrandComponent from './MoreFromBrand.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container */
export class MoreFromBrandContainer extends DataContainer {
    containerFunctions = {
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            title: '',
            items: []
        };
    }

    componentDidMount() {
        const { productId } = this.props;
        if (productId) {
            this.fetchMoreFromBrandData();
        }
    }

    componentDidUpdate(prevProps) {
        const { productId } = this.props;
        const { productId: prevProductId } = prevProps;
        if (prevProductId !== productId) {
            this.fetchMoreFromBrandData();
        }
    }

    containerProps() {
        const { title } = this.state;

        return {
            title,
            items: this.getIndexedItems()
        };
    }

    getIndexedItems() {
        const { items } = this.state;

        // return items.map(getIndexedProduct);
        return Array.isArray(items) ? items.map(getIndexedProduct) : [];
    }

    async fetchMoreFromBrandData() {
        const { productId } = this.props;

        try {

            const { amMoreFromThisBrand } = await executeGet(
                prepareQuery(
                    BrandsMoreFromBrandQuery.getQuery({ productId })
                ),
                'MoreFromBrands',
                ONE_DAY
            );

            if(amMoreFromThisBrand?.length > 0) {
                const relatedSKUs = amMoreFromThisBrand.map(product => product.sku);
                const options = {
                    args: {
                        filter: {
                            productsSkuArray: relatedSKUs,
                        },
                    },
                    notRequireInfo: true,
                };

                const { products: { items } = {} } = await executeGet(
                    prepareQuery(
                        ProductListQuery.getQuery(options)
                    ),
                    'MoreFromBrands',
                    ONE_DAY
                );

                const title = `More from ${amMoreFromThisBrand[0]?.manufacturer}`;

                this.setState({
                    title:title,
                    items:items
                });
            }
            else {
                this.setState({
                    title: '',
                    items: []
                });
            }

            
        } catch (e) {
            showNotification(
                'error',
                __('Error fetching MoreFromBrands!'),
                e
            );
        }
            // .then(
            //     /** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/executeGet/then */
            //     ({ amMoreFromThisBrand: { title, items } = {} }) => this.setState({
            //         title,
            //         items
            //     })
            // )
            // .catch(
            //     /** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/executeGet/then/catch */
            //     (e) => showNotification('error', __('Error fetching MoreFromBrands!'), e)
            // );
   // }
    }

    render() {
        return (
            <MoreFromBrandComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFromBrandContainer);
