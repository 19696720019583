/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import PaypalMessages from '../../component/PaypalMessages';
import { PAYPAL_PLACEMENT_PRODUCT_PAGE } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalMessagesToProductPage = (args, callback) => {
    const result = callback(...args);
    // vvv we need to select first child of the result because Product Page is wrapped with NoMatchHandler
    const ProductPage = Children.toArray(result.props.children)[0];
    const children = Children.toArray(ProductPage.props.children);

    return (
        <>
            { cloneElement(ProductPage, { ...ProductPage.props }, [
                <PaypalMessages
                  key={ PAYPAL_PLACEMENT_PRODUCT_PAGE }
                  page={ PAYPAL_PLACEMENT_PRODUCT_PAGE }
                />,
                ...children
            ]) }
        </>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render: addPayPalMessagesToProductPage
        }
    }
};
