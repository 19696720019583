/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getProductDetailsData } from '../data/product';
import { GTM_EVENT_KEY_PRODUCT_DETAIL } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Product/validateProduct */
export function validateProduct(product) {
    return (
        !!product.id
    );
}

/** @namespace Scandiweb/Gtm/Event/Product/fireProductDetailsEvent */
export const fireProductDetailsEvent = (product) => {
    const {
        events: {
            gtm_product_detail
        } = {},
        lastProduct = {}
    } = getStore().getState().ConfigReducer.gtm;

    if (gtm_product_detail && validateProduct(product)) {
        const execute = debounceCallback(async (product) => {
            if (lastProduct.id !== product.id) {
                pushToDataLayer({
                    event: GTM_EVENT_KEY_PRODUCT_DETAIL,
                    ...await getProductDetailsData(product)
                });
                getStore().getState().ConfigReducer.gtm.lastProduct = product;
            }
        });

        execute(product);
    }
};
